@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

#root {
    min-height: 100vh;
    background-color: #f0f0f0; /* Temporary for testing */
}

.canvas {
    width: unset;
    height: 700px !important;
}
@media (max-width: 992px) {
    canvas {
        width: 100% !important;
        height: auto !important; /* Use 'auto' or specific values */
        min-height:400px;
        max-height: 500px;
    }
}
